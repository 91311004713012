import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/Pkw"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { TwoChoice } from "../../components/twoChoice"
import { FormPKW } from "../../components/FormPKW"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { BottomCta, RefinSeoBgImg } from "../../components/BottomCta"
import { Partners } from "../../components/PartnersNew"
import { Questions } from "../../components/Questions"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"
import { QUESTIONS_FOR_INDEX_PKW } from "../../components/Questions/helpers"
import { useHybridBanner } from "../../hooks/useHybridBanner"

const titleForm = (
  <>
    Заполните форму <br /> и получите карту
  </>
)

const twoChoicetextBlockTitle = (
  <>
    Есть «Халва»? Порекомендуйте карту Халва другу и получите
    <br /> 1 500 бонусных баллов
  </>
)
const secondTwoChoicetextBlockTitle = (
  <>
    Оформите карту и получите
    <br /> 1 500 бонусных баллов
  </>
)

const bannerTitle = "Дарим 1500 рублей"

const bannerDescription = `
за оформление карты Халва бонусными баллами. <br/> 1 балл = 1 рубль
`

const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  const banner = useHybridBanner(
    Banner as any,
    { title: bannerTitle, desc: bannerDescription, orderNum: "1" },
    { orderNum: "1", title: bannerTitle, variant: "pkwBonus" },
    { orderNum: "1", isDynamicText: true },
    data.allDynamicBanner
  )

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank showHint={false} hasCTA />
      <div>{banner}</div>
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwBonus1500"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как получить 1 500 бонусных баллов"
        variant="bonus1500"
        orderNum="3"
      />
      <TwoChoice
        variant="1500bonus"
        textBlockTitle={twoChoicetextBlockTitle}
        secondTextBlockTitle={secondTwoChoicetextBlockTitle}
        orderNum="4"
        swapCardText
        swapBtnText
      />
      <FormPKW
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="5"
        onlyPKWPage
        isGreenApprove
      />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="6"
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="7" />
      <Questions questionList={QUESTIONS_FOR_INDEX_PKW} orderNum="8" />
      <NewFooter ligal={ligal} orderNum="8" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/bonus-1500/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
